import { useEffect, useRef } from 'react';
import { CONTENTS_OBJECT } from '@/common/model/constants';

const loadingImg = Public.GetLoadingImage('svg');

const SWIPER_ROOT_ID = 'div_brand_premium_banner_slide';

const LIST = PageVariable[CONTENTS_OBJECT.BrandPremiumList.DATA_NAME] || [];

const BrandPremium = () => {
  const banner_swiper = useRef(null);

  const chk_resize = useRef(null);

  useEffect(() => {
    if (LIST.length === 0) return;

    const is_loop = LIST?.length > 1 ? true : false;
    const is_touch = LIST?.length > 1 ? true : false;

    banner_swiper.current = SlideHelper.CreateSlide(`#${SWIPER_ROOT_ID}`, {
      currentX: 0,
      simulateTouch: is_touch,
      loop: is_loop,
      nextButton: '.slide-button__next',
      prevButton: '.slide-button__prev',
      autoplay: 4500,
      watchSlidesVisibility: true,
      onTouchStart: function (slider) {
        this.currentX = slider.touches.currentX;
      },
      onTouchEnd: function (slider, event) {
        if (this.currentX == slider.touches.currentX) {
          if ($(event.target).is('.slide-button__next') || $(event.target).is('.slide-button__prev')) {
            // 아무것도 하지 않는다.
          } else {
            location.href = $(slider.slides[slider.activeIndex]).children('a').attr('href');
          }
        }

        this.currentX = 0;
      },
    });
  }, []);

  if (LIST.length === 0) {
    return null;
  }

  $(window).on('resize', function () {
    if (chk_resize.current) {
      clearTimeout(chk_resize.current);
    }

    chk_resize.current = setTimeout(function () {
      banner_swiper.current.update();
    }, 500);
  });

  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'visible') {
      banner_swiper.current.startAutoplay();
    } else {
      banner_swiper.current.stopAutoplay();
    }
  });

  window.addEventListener('scroll', function () {
    // 현재 로딩된 li의 이미지가 로딩이 안된 경우
    const active_li_img = $(`#${SWIPER_ROOT_ID} ul li.slide.active a img`);
    if (active_li_img.attr('load') === 'F') {
      DelayImageLoading.GetImageResizeTarget(active_li_img);
    }
  });

  const rand = Math.floor(Math.random() * 10);
  return (
    <div className="main-brandavenue__banner">
      <div className="slide-button">
        <button type="button" className="slide-button__prev">
          prev
        </button>
        <button type="button" className="slide-button__next">
          next
        </button>
      </div>
      <div
        className="slide_control horizontal"
        id={SWIPER_ROOT_ID}
        data-random_index={rand + 1}
        onMouseEnter={() => {
          if (banner_swiper.current) banner_swiper.current.stopAutoplay();
        }}
        onMouseLeave={() => {
          if (banner_swiper.current) banner_swiper.current.startAutoplay();
        }}
      >
        <ul className="cont">
          {LIST.map((item, index) => {
            return (
              <li className="slide" key={index} data-type={''}>
                <a target="_blank" href={item.LINK_URL} title={item.TITLE} rel="noreferrer">
                  <img src={loadingImg} gd_src={item.IMG_URL} width="1250" height="99" alt={item.TITLE} load="N" />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default BrandPremium;
