const loadingImg = Public.GetLoadingImage('svg');
const Chance = ({ item }) => {
  let title = tw.TITLE;
  let comment = tw.COMMENT;
  if (!title && comment) {
    title = comment;
    comment = '';
  }
  return (
    <li className="tweet-chance">
      <a href={item.LINK_URL} title={comment}>
        <div className="tweet-chance__contents">
          <div className="tweet-chance__thumb">
            <img gd_src={item.IMG_URL} src={loadingImg} load="N" alt={comment} />
          </div>
          <div className="tweet-chance__detail">
            <div className="title">{comment}</div>
            <div className="price">
              <img
                className="price__img"
                gd_src={item.MAME_IMG_URL}
                src={loadingImg}
                load="N"
                alt="brandmon"
                width="16"
                height="16"
              />{' '}
              {item.DISPLAY_FINAL_PRICE}
            </div>
          </div>
        </div>
        <div className="tweet-chance__comment">{title}</div>
      </a>
    </li>
  );
};

export default Chance;
