import { useEffect, useState } from 'react';
import ItemCardArea from '../ItemCardArea';

const SingleContents = ({ wePickItems, wepickData, wid, priority, slideFunc }) => {
  const { TITLE, DISP_TYPE, LINK_URL, CONTENTS_TYPE } = wepickData;
  const { ETC_INFO, ITEMS, BANNER } = wePickItems;

  // 데이터 검증
  if ((CONTENTS_TYPE !== 'BA' && ITEMS?.length <= 0) || (CONTENTS_TYPE === 'BA' && !BANNER.HTML)) {
    $(`#io_${priority}`).remove();
    return;
  }
  const etcInfoObj = Qoo10FE.Util.Object.jsonParse(ETC_INFO);

  const Timer = () => {
    const ts_end_time = etcInfoObj && etcInfoObj['ts_end_time'] ? etcInfoObj['ts_end_time'] : '';
    const ts_open_time = etcInfoObj && etcInfoObj['ts_open_time'] ? etcInfoObj['ts_open_time'] : '';
    useEffect(() => {
      if (ts_end_time)
        Countdown.Create('TimeSaleTimer', `ts_count_down_${wid}`, ts_end_time, 'first_load', 'inner_html');
    }, [ts_end_time]);

    return ts_end_time ? (
      <div className="time-sale">
        <span className="time-sale__count" id={`ts_count_down_${wid}`}>
          <span data-type="count"></span>
        </span>
      </div>
    ) : ts_open_time ? (
      <div className="time-sale">
        <p className="time-sale__info">{ts_open_time}</p>
      </div>
    ) : null;
  };

  const PBToolTip = () => {
    const [isPBTootipLayerView, setIsPBTootipLayerView] = useState(false);
    return (
      <div className="c-tooltip">
        <button
          type="button"
          className="info-button"
          onMouseOver={() => {
            setIsPBTootipLayerView(true);
          }}
          onMouseLeave={() => {
            setIsPBTootipLayerView(false);
          }}
        >
          {MultiLang.findCommonResource('Main/us/index.aspx', 'Sponsored')}
        </button>
        <div
          className="c-tooltip__cont"
          style={{
            display: !isPBTootipLayerView ? 'none' : null,
            top: 'calc(100% + 5px)',
            left: 'calc(50% - 115px)',
          }}
        >
          <div>
            {MultiLang.findCommonResource(
              'Main/us/index.aspx',
              '광고를 구매한 상품으로 판매자의 광고 구매금액과 광고 반응률을 고려하여 노출됩니다.',
            )}
          </div>
        </div>
      </div>
    );
  };

  const BannerTypeHtml = () => {
    return <div dangerouslySetInnerHTML={{ __html: BANNER.HTML }}></div>;
  };

  const isPagingDisplay = DISP_TYPE === 'S' && ITEMS.length > 5;
  return (
    <>
      {CONTENTS_TYPE === 'BA' ? (
        <BannerTypeHtml />
      ) : (
        <>
          <div className="main-row__head">
            <h2 className="main-title">{LINK_URL ? <a href={LINK_URL}>{TITLE}</a> : TITLE}</h2>
            {CONTENTS_TYPE === 'TD' ? <Timer /> : null}
            {CONTENTS_TYPE === 'PB' ? <PBToolTip /> : null}
            {isPagingDisplay ? (
              <div className="slide-paging" id={`dv_wepick_paging_${wid}`}></div>
            ) : LINK_URL ? (
              <a className="main-row__link" href={LINK_URL} role="button">
                {MultiLang.findCommonResource('Main/us/index.aspx', 'See all')}
              </a>
            ) : null}
          </div>
          <ItemCardArea LIST={ITEMS} wepickData={wepickData} slideFunc={slideFunc} priority={priority} />
          {isPagingDisplay && LINK_URL ? (
            <div className="list-moreview">
              <button
                type="button"
                className="button"
                id={`dv_wepick_viewmore_${wid}`}
                onClick={() => {
                  window.location.href = LINK_URL;
                }}
              >
                {MultiLang.findCommonResource('Main/us/index.aspx', 'See all')}
              </button>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default SingleContents;
