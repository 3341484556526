import { CONTENTS_OBJECT } from '@/common/model/constants';
import { useEffect, useState } from 'react';
import BrandGoodsList from './BrandGoodsList';
import BrandPremium from './BrandPremium';

const loadingImg = Public.GetLoadingImage('svg');

const SWIPER_ROOT_ID = 'div_brand_avenue';
const SWIPER_NEXT_BUTTON_ID = 'fe_btn_brand_avenue_next';
const SWIPER_PREV_BUTTON_ID = 'fe_btn_brand_avenue_prev';
const SLIDE_SELECT_CLASS = 'slide--active';

const data = PageVariable[CONTENTS_OBJECT.BrandAvenue.DATA_NAME] || {};
const LIST = data.DISPLAY_BRANDS || [];

const BrandAvenue = () => {
  const [brandGoodsList, setBrandGoodsList] = useState({});
  const [selectedBrandNo, setSelectedBrandNo] = useState();
  useEffect(() => {
    const displayCnt = 11; //화면에 보이는 카운트
    const randomCnt = Math.min(displayCnt, LIST.length);
    const randomIndex = parseInt(Math.random() * randomCnt);

    const activeBrand = $(`#${SWIPER_ROOT_ID} div[role='tablist']`).find('a').eq(randomIndex);

    if (LIST.length === 0) return;

    new Swiper(`#${SWIPER_ROOT_ID}`, {
      slidesPerView: 11,
      slidesPerGroup: 11,
      clickable: true,
      preloadImages: false,
      resistance: true,
      resistanceRatio: 0,
      lazyLoading: true,
      spaceBetween: 4,
      nextButton: '#fe_btn_brand_avenue_next',
      prevButton: '#fe_btn_brand_avenue_prev',
      onInit: () => {
        activeBrand?.addClass(SLIDE_SELECT_CLASS);
      },
    });

    mouseOver({ brandNo: activeBrand.attr('data-brand_no'), gdNoList: activeBrand.attr('data-gd_no_list') });
  }, []);

  const mouseOver = ({ brandNo, gdNoList }) => {
    setSelectedBrandNo(brandNo);
    const target = $(`#${SWIPER_ROOT_ID} div[role='tablist'] a[role=tab][data-brand_no=${brandNo}]`);
    const currentTarget = $(`#${SWIPER_ROOT_ID} div[role='tablist'] a[role=tab]`);
    currentTarget.removeClass(SLIDE_SELECT_CLASS);
    target.addClass(SLIDE_SELECT_CLASS);

    if (brandGoodsList[brandNo]) {
      $(`ul[data-type=ul_brand_goods_list] li[data-brand_no=${brandNo}]`).show();

      return;
    }

    const param = new RMSParam();
    param.add('gd_no_list', gdNoList);
    param.add('brand_no', brandNo);

    RMSHelper.asyncCallWebMethod(
      //Public.getServiceUrl('main/FEIndex1API.aspx'),
      Public.getServiceUrl('Main/FEMainAPI.aspx'),
      'GetGoodsData',
      param.toJson(),
      function (result) {
        if (result?.length > 0) {
          result.map((item) => {
            item.BRAND_NO = brandNo;
          });

          setBrandGoodsList({ ...brandGoodsList, [brandNo]: result });
        }
      },
    );
  };

  if (LIST.length === 0) {
    return null;
  }

  const brandAvenueUrl = Public.getServiceUrl('Search/BrandAvenue.aspx');

  return (
    <div className="main-row">
      <div className="main-row__head">
        <h2 className="main-title">
          <a href={brandAvenueUrl}>{data.TITLE}</a>
        </h2>
        <a className="main-row__link" href={brandAvenueUrl} role="button">
          {MultiLang.findCommonResource('Main/us/index.aspx', 'See all')}
        </a>
      </div>
      <div className="main-brandavenue">
        <div className="main-brandavenue__list">
          <div className="slide-button" data-type="div_main_brand_btn">
            <button type="button" className="slide-button__prev" id={SWIPER_PREV_BUTTON_ID}>
              prev
            </button>
            <button type="button" className="slide-button__next" id={SWIPER_NEXT_BUTTON_ID}>
              next
            </button>
          </div>
          <div className="slide_control" id="div_brand_avenue" data-item_count={LIST.length}>
            <div className="cont" role="tablist">
              {LIST.map((item, index) => {
                return (
                  <a
                    key={item.BRAND_NO + index}
                    role="tab"
                    className="slide"
                    data-brand_no={item.BRAND_NO}
                    data-gd_no_list={item.BRAND_GD_NO_LIST}
                    onMouseOver={(event) => {
                      if (event.target instanceof HTMLAnchorElement) {
                        mouseOver({ brandNo: item.BRAND_NO, gdNoList: item.BRAND_GD_NO_LIST });
                      }
                    }}
                  >
                    <img
                      src={loadingImg}
                      gd_src={item.BRAND_LOGO_IMG}
                      load="N"
                      resizing="false"
                      width="90"
                      height="42"
                      alt={item.BRAND_NM}
                    />
                  </a>
                );
              })}
            </div>
          </div>
          <BrandGoodsList brandGoodsList={brandGoodsList[selectedBrandNo]} />
        </div>
        <BrandPremium />
      </div>
    </div>
  );
};
export default BrandAvenue;
