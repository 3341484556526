import { CONTENTS_OBJECT } from '@/common/model/constants';
import Premium from './Premium';
import Chance from './Chance';

const ShoppingTweet = () => {
  const LIST = PageVariable[CONTENTS_OBJECT.ShoppingTweet.DATA_NAME] || [];

  if (LIST.length === 0) {
    return null;
  }

  return (
    <div className="main-row">
      <div className="main-row__head">
        <h2 className="main-title">{MultiLang.findCommonResource('Main/us/index.aspx', 'Shopping Tweet')}</h2>
      </div>
      <div className="main-tweet">
        <ul className="main-tweet__list">
          {LIST.map((item, index) => {
            const { TWEET_TYPE } = item;
            return TWEET_TYPE === 'R' ? <Chance item={item} key={index} /> : <Premium item={item} key={index} />;
          })}
        </ul>
      </div>
    </div>
  );
};
export default ShoppingTweet;
