import { useEffect } from 'react';
import ItemCardArea from '../ItemCardArea';

const MultiContents = ({ wePickItemsList, wepickDataList, widList, priority, slideFunc }) => {
  // 데이터 검증
  const keyList = widList.filter((item) => wePickItemsList[item].ITEMS.length > 0);

  if (!keyList) {
    $(`[data-type=io_div][data-priority=${priority}]`).remove();
    return;
  }

  const Timer = ({ ETC_INFO, wid, TITLE }) => {
    const etcInfoObj = Qoo10FE.Util.Object.jsonParse(ETC_INFO);
    const ts_end_time = etcInfoObj && etcInfoObj['ts_end_time'] ? etcInfoObj['ts_end_time'] : '';
    const ts_open_time = etcInfoObj && etcInfoObj['ts_open_time'] ? etcInfoObj['ts_open_time'] : '';
    useEffect(() => {
      if (ts_end_time)
        Countdown.Create('TimeSaleTimer', `ts_count_down_${wid}`, ts_end_time, 'first_load', 'inner_html');
    }, [ts_end_time, wid]);

    return (
      <>
        {TITLE}
        {ts_end_time ? (
          <strong className="main-tab__info main-tab__info--count" id={`ts_count_down_${wid}`}>
            <span data-type="count"></span>
          </strong>
        ) : ts_open_time ? (
          <strong className="main-tab__info">{ts_open_time}</strong>
        ) : null}
      </>
    );
  };

  const ItemCard = ({ wid, index }) => {
    return (
      <>
        <ItemCardArea
          LIST={wePickItemsList[wid].ITEMS}
          wepickData={wepickDataList[wid]}
          isDisplay={index === 0}
          slideFunc={slideFunc}
          priority={priority}
        />
        {wepickDataList[wid].LINK_URL ? (
          <div className="list-moreview" id={`dv_wepick_viewmore_${wid}`} style={{ display: index > 0 ? 'none' : '' }}>
            <button
              type="button"
              className="button"
              onClick={() => {
                window.location.href = wepickDataList[wid].LINK_URL;
              }}
            >
              {MultiLang.findCommonResource('Main/us/index.aspx', 'See all')}
            </button>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className="main-row__head">
        <div className="main-tab" role="tablist">
          {keyList.map((wid, index) => {
            const { TITLE, CONTENTS_TYPE } = wepickDataList[wid];
            const { ETC_INFO } = wePickItemsList[wid];
            return (
              <button
                type="button"
                role="tab"
                key={'tab' + priority + index + wid}
                className={index === 0 ? 'active' : ''}
                data-wid={wid}
                onClick={() => {
                  //setSelectedWid(wid);
                  const targetEl = $(`#io_${priority.replace('.', '_')}`);
                  //tab class 조정
                  targetEl.find('div.main-tab button').removeClass('active');
                  targetEl.find(`div.main-tab button[data-wid=${wid}]`).addClass('active');
                  //title쪽 페이징 조정
                  targetEl.find(`[id^=dv_wepick_paging_]`).hide();
                  targetEl.find(`[id=dv_wepick_paging_${wid}]`).show();
                  //contents slide 조정
                  targetEl.find(`[id^=dv_wepick_cardlist_]`).hide();
                  targetEl.find(`[id=dv_wepick_cardlist_${wid}]`).show();
                  //viewmore 조정
                  targetEl.find(`[id^=dv_wepick_viewmore_]`).hide();
                  targetEl.find(`[id=dv_wepick_viewmore_${wid}]`).show();
                  slideFunc(wid);
                }}
              >
                {CONTENTS_TYPE === 'TD' ? (
                  <Timer ETC_INFO={ETC_INFO} wid={wid} TITLE={TITLE} />
                ) : (
                  <div className="main-tab__fixed">{TITLE}</div>
                )}
              </button>
            );
          })}
        </div>
        {keyList.map((wid, index) => {
          return wePickItemsList[wid].ITEMS.length > 5 ? (
            <div
              key={'paging' + priority + index + wid}
              className="slide-paging"
              id={`dv_wepick_paging_${wid}`}
              style={{ display: index > 0 ? 'none' : '' }}
            ></div>
          ) : null;
        })}
      </div>
      {keyList.map((wid, index) => {
        return <ItemCard key={'cardarea' + priority + index + wid} wid={wid} index={index} />;
      })}
    </>
  );
};

export default MultiContents;
