import usePrevious from '../../common/hooks/usePrevious';

const loadingImg = Public.GetLoadingImage('svg');

const BrandGoodsList = ({ brandGoodsList }) => {
  const prevBrandGoodsList = usePrevious(brandGoodsList);

  let viewBrandGoodsList = brandGoodsList;
  //데이터 조회중일때 이전 데이터로 노출 -> 깜박임 방지
  if (!brandGoodsList && prevBrandGoodsList) viewBrandGoodsList = prevBrandGoodsList;

  const { PItem } = Qoo10FEComponents;

  return (
    <div className="p-itemcard p-itemcard--grid4" role="tabpanel" style={{ minHeight: '120px', maxHeight: '120px' }}>
      <ul className="p-itemcard__list" data-type="ul_brand_goods_list">
        {!viewBrandGoodsList?.length
          ? [0, 1, 2, 3].map((item) => (
              <li key={item} className="p-item" data-id="load">
                <a className="p-item__figure">
                  <img className="p-item__thumb" src={loadingImg} width="120" height="120" />
                </a>
              </li>
            ))
          : viewBrandGoodsList.map((item, index) => {
              return (
                <PItem
                  item={item}
                  type={'brandItem'}
                  key={item.BRAND_NO + index + '1'}
                  liClass={'p-item'}
                  imgSize={120}
                  useLazyLoading={false}
                  //useRateNReviewCnt={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? true : false}
                  useRemainCnt={false}
                  useRecommendCnt={false}
                  useShippingInfo={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? false : true}
                />
              );
            })}
      </ul>
    </div>
  );
};
export default BrandGoodsList;
