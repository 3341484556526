export const CONTENTS_OBJECT = {
  MainBannerList: { DATA_NAME: 'MainBannerList' },
  BrandAvenue: { DATA_NAME: 'BrandAvenue' },
  BrandPremiumList: { DATA_NAME: 'BrandPremiumBannerList' },
  SlideBannerList: { DATA_NAME: 'SlideBannerList' },
  KeyCornerList: { DATA_NAME: 'KeyCornerList' },
  ShoppingTweet: { DATA_NAME: 'ShoppingTweet' },
  WepickItems: { DATA_NAME: 'WepickItems' },
  WepickList: { DATA_NAME: 'WepickList' },
};
