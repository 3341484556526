import { useEffect } from 'react';

const loadingImg = Public.GetLoadingImage('svg');
const Premium = ({ item }) => {
  useEffect(() => {
    window.addEventListener('load', function () {
      //Video
      UIVideoUtil.init('M');
      // Following 셀러샵
      UICommonFollowingShop.init();
    });
  }, []);

  let is_display_video = item.VC_NO > 0 && item.VIDEO_THUMB_IMG && item.VIDEO_PLAY_URL;
  const li_video_attr = is_display_video ? { 'data-video_id': `div_video_tw_${item.VC_NO}` } : null;
  let imgUrl = item.IMG_URL;
  let title = item.TITLE;
  let comment = item.COMMENT;
  if (!title && comment) {
    title = comment;
    comment = '';
  }

  // IOS 테블릿앱인경우 비디오를 만들지 않는다.
  if (is_display_video && (DeviceUtil.isIOSShoppingApp4Tablet() || DeviceUtil.isIOSQubeApp4Tablet())) {
    is_display_video = false;
    imgUrl = item.VIDEO_THUMB_IMG;
  }
  return (
    <li {...li_video_attr}>
      <a href={item.LINK_URL}>
        {/* 비디오 표시되어야하는 경우 */}
        {is_display_video ? (
          <div className="main-tweet__thumb main-tweet__thumb--video" title="Live Viewer">
            <span
              id={`div_video_tw_${item.VC_NO}`}
              data-type="liveShopPlayer"
              data-auto_play_flag="M"
              data-video_id={`video_info_DDS_${item.VC_NO}`}
              //data-video_load_type={item.VIDEO_TYPE != 'YT' ? 'new' : null}
            >
              <div
                id={`video_info_DDS_${item.VC_NO}`}
                data-common_video="Y"
                data-vc_no={item.VC_NO}
                data-thumb_img={item.VIDEO_THUMB_IMG}
                data-play_url={item.VIDEO_PLAY_URL}
                data-auto_play_flag="M"
                data-video_load="N"
                data-video_type={item.VIDEO_TYPE}
                //data-video_load_type={item.VIDEO_TYPE != 'YT' ? 'new' : null}
              ></div>
            </span>
          </div>
        ) : imgUrl ? ( // 이미지가 빈 값일 경우 이미지 영역 표기안함
          <div className="main-tweet__thumb">
            <img gd_src={imgUrl} src={loadingImg} load="N" alt="" resizing="false" width="300" height="168" />
          </div>
        ) : null}

        {/* 브랜드몬 잔여량 있는경우 노출 */}
        {item.TYPE === 'P' || (item.TYPE === 'T' && item.BRANDMON_IMG_URL && item.REMAIN_BRANDMON_AMOUNT > 0) ? (
          <div className="main-tweet__detail main-tweet__detail--store">
            {item.TYPE === 'T' && item.BRANDMON_IMG_URL && item.REMAIN_BRANDMON_AMOUNT > 0 ? (
              <>
                <p
                  className="comment comment--mon"
                  dangerouslySetInnerHTML={{
                    __html: String.format(
                      MultiLang.findCommonResource('Main/us/index.aspx', 'Brandmon Remains'),
                      item.BRANDMON_TITLE,
                      item.REMAIN_BRANDMON_AMOUNT,
                    ),
                  }}
                >
                  {item.TYPE === 'P' && comment ? item.COMMENT : null}
                </p>
                <div className="title">{item.TITLE}</div>
                <span className="store">
                  <span
                    className="store__img"
                    gd_src={item.BRANDMON_IMG_URL}
                    style={{
                      backgroundImage: `url(${loadingImg})`,
                    }}
                    load="N"
                  ></span>
                  {item.SHOP_TITLE}
                </span>
              </>
            ) : (
              <>
                {item.TYPE === 'P' && item.COMMENT ? <p className="comment">{item.COMMENT}</p> : null}
                <div className="title">{item.TITLE}</div>
              </>
            )}
            {item.TYPE === 'P' ? (
              <span className="store">
                <span
                  className="store__img"
                  gd_src={item.SHOP_IMAGE}
                  style={{
                    backgroundImage: `url(${loadingImg})`,
                  }}
                  load="N"
                ></span>
                {item.SHOP_TITLE}
              </span>
            ) : null}
          </div>
        ) : (
          <div className="main-tweet__detail">
            <div className="title">{title}</div>
          </div>
        )}
      </a>
    </li>
  );
};

export default Premium;
