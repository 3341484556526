import { useEffect, useRef } from 'react';
import { CONTENTS_OBJECT } from '@/common/model/constants';

const loadingImg = Public.GetLoadingImage('svg');

const SWIPER_ROOT_ID = 'div_main_sub_banner_slide';

const LIST = PageVariable[CONTENTS_OBJECT.SlideBannerList.DATA_NAME] || [];

const SlideBannerList = () => {
  const banner_swiper = useRef(null);

  const chk_resize = useRef(null);

  useEffect(() => {
    if (LIST.length < 2) return;

    banner_swiper.current = SlideHelper.CreateSlide(`#${SWIPER_ROOT_ID}`, {
      currentX: 0,
      autoplay: 3000,
      autoplayDisableOnInteraction: false,
      loop: true,
      effect: 'fade',
      touchRatio: 0,
      loopedSlides: 1,
      nextButton: '.btn-next',
      prevButton: '.btn-prev',
      onTouchStart: function (slider) {
        this.currentX = slider.touches.currentX;
      },
      onTouchEnd: function (slider, event) {
        if (this.currentX == slider.touches.currentX) {
          if ($(event.target).is('.btn-prev') || $(event.target).is('.btn-next')) {
            // 아무것도 하지 않는다.
          } else {
            location.href = $(slider.slides[slider.activeIndex]).children('a').attr('href');
          }
        }

        this.currentX = 0;
      },

      onTransitionEnd: function () {
        const active_li = $(`#${SWIPER_ROOT_ID} ul li.slide.active`);
        const active_li_img = active_li.find('a img');

        // 현재 로딩된 li의 이미지가 로딩이 안된 경우
        if (active_li_img.attr('load') === 'F') {
          DelayImageLoading.GetImageResizeTarget(active_li_img);
        }

        // 현재 로딩된 li의 이전 li 이미지가 로딩이 안된 경우
        if (active_li.prev('li').find('a img').attr('load') === 'F') {
          DelayImageLoading.GetImageResizeTarget(active_li.prev('li').find('a img'));
        }

        // 현재 로딩된 li의 다음 li 이미지가 로딩이 안된 경우
        if (active_li.next('li').find('a img').attr('load') === 'F') {
          DelayImageLoading.GetImageResizeTarget(active_li.next('li').find('a img'));
        }
      },
    });
  }, []);

  if (LIST.length === 0) {
    return null;
  }

  $(window).on('resize', function () {
    if (chk_resize.current) {
      clearTimeout(chk_resize.current);
    }

    chk_resize.current = setTimeout(function () {
      if (banner_swiper.current) banner_swiper.current.update();
    }, 500);
  });

  document.addEventListener('visibilitychange', function () {
    if (banner_swiper.current) {
      if (document.visibilityState === 'visible') {
        banner_swiper.current.startAutoplay();
      } else {
        banner_swiper.current.stopAutoplay();
      }
    }
  });

  window.addEventListener('scroll', function () {
    // 현재 로딩된 li의 이미지가 로딩이 안된 경우
    const active_li_img = $(`#${SWIPER_ROOT_ID} ul li.slide.active a img`);
    if (active_li_img.attr('load') === 'F') {
      DelayImageLoading.GetImageResizeTarget(active_li_img);
    }
  });

  const rand = Math.floor(Math.random() * 10);

  return (
    <div className="main-issuebanner">
      <div className="main-inner">
        <div
          className="slide_control"
          id={SWIPER_ROOT_ID}
          data-random_index={rand + 1}
          onMouseEnter={() => {
            if (banner_swiper.current) banner_swiper.current.stopAutoplay();
          }}
          onMouseLeave={() => {
            if (banner_swiper.current) banner_swiper.current.startAutoplay();
          }}
        >
          <div className="main-bannerctrl">
            {LIST.length > 1 ? (
              <>
                <button className="btn-prev" type="button">
                  Previous slide
                </button>
                <button className="btn-next" type="button">
                  Next slide
                </button>
              </>
            ) : null}
          </div>
          <ul className="cont">
            {LIST.map((item, index) => {
              return (
                <li className="slide" key={index}>
                  <a href={item.LINK_URL}>
                    <img src={loadingImg} gd_src={item.IMG_URL} width="1260" height="126" load="N" alt={item.TITLE} />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SlideBannerList;
