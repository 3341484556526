import { CONTENTS_OBJECT } from '@/common/model/constants';
import { createRoot } from 'react-dom/client';
import MultiContents from './Contents/Multi';
import SingleContents from './Contents/Single';
import { useEffect } from 'react';

const wePickObj = CONTENTS_OBJECT.WepickList;
const wepickList = PageVariable[wePickObj.DATA_NAME];
const wepickData = wepickList?.reduce(
  (acc, item) => ({
    ...acc,
    [item.WID]: item,
  }),
  {},
);
const wePickItemsObj = CONTENTS_OBJECT.WepickItems;
const wepickItems = PageVariable[wePickItemsObj.DATA_NAME];

const WePickComp = ({ priorityList }) => {
  useEffect(() => {
    // 캐쉬 데이터 조회
    const getNormalWidReq = (widList) => {
      return new Promise(function (resolve) {
        const normalWidList = widList.filter((w) => wepickData[w].CONTENTS_TYPE !== 'QBO');
        if (normalWidList.length === 0) {
          resolve(null);
          return;
        }

        // RMSHelper.asyncCallWebObject(
        //   Public.getServiceUrl(`Main/FEWepickAPI.aspx?wid_list=${normalWidListArr.toString()}`),
        //   'post',
        //   null,
        //   function (result, svc, xmlHttp) {
        //     if (result) {
        //       try {
        //         resolve(JSON.parse(result));
        //       } catch {
        //         /* empty */
        //       }
        //     }
        //   },
        // );
        const param = new RMSParam();
        param.add('wid_list', normalWidList.toString());
        param.add('display_channel', 'FR');
        RMSHelper.asyncCallWebMethod(
          Public.getServiceUrl('swe_MainAjaxService.asmx'),
          'GetWepickItems',
          param.toJson(),
          function (result, svc, methodName, xmlHttpasync) {
            resolve(result);
          },
        );
      });
    };

    // qbo 개인화 데이터 조회
    const getQooboWidReq = (widList) => {
      return new Promise(function (resolve) {
        const qooboWidList = widList.filter((w) => wepickData[w].CONTENTS_TYPE === 'QBO');
        if (qooboWidList.length === 0) {
          resolve(null);
          return;
        }

        const param = new RMSParam();
        param.add('wid_list', qooboWidList.toString());
        param.add('display_channel', 'FR');
        RMSHelper.asyncCallWebMethod(
          Public.getServiceUrl('Main/FEMainAPI.aspx'),
          'GetWepickItems',
          param.toJson(),
          function (result, svc, methodName, xmlHttpasync) {
            resolve(result);
          },
        );
      });
    };

    function renderItems(target) {
      const widList = target.dataset['wid_list'].split(',');
      function render(list) {
        const slideFunc = (wid, count, onSlideChangeEnd) => {
          // slide swiper 세팅
          if ($(`#dv_wepick_slide_${wid}`).length > 0 && !$(`#dv_wepick_slide_${wid}`).hasClass('horizontal')) {
            new Swiper(`#dv_wepick_slide_${wid}`, {
              slidesPerView: 'auto',
              pagination: `#dv_wepick_paging_${wid}`,
              paginationClickable: true,
              prevButton: `#btn_wepick_prev_${wid}`,
              nextButton: `#btn_wepick_next_${wid}`,
              loop: true,
              onSlideChangeEnd,
            });

            if (count < 6) {
              $(`#dv_wepick_paging_${wid}`).hide();
            }
          }
        };

        const priority = target.dataset['priority'];
        target.style.height = '';

        if (widList.length > 1) {
          createRoot(target).render(
            <MultiContents
              wePickItemsList={list}
              wepickDataList={wepickData}
              widList={widList}
              priority={priority}
              slideFunc={slideFunc}
            />,
          );
        } else {
          const index = widList[0];
          createRoot(target).render(
            <SingleContents
              wePickItems={list[index]}
              wepickData={wepickData[index]}
              wid={index}
              priority={priority}
              slideFunc={slideFunc}
            />,
          );
        }
      }

      if (wepickItems[widList[0]]) {
        render(wepickItems);
      } else {
        Promise.all([getNormalWidReq(widList), getQooboWidReq(widList)]).then((result) => {
          const merged = {};
          result.forEach((item) => {
            if (!item) return;
            Object.keys(item).forEach((key) => (merged[key] = item[key]));
          });
          render(merged);
        });
      }
    }
    // 화면에 보이지 않는 dom observer로 셋팅
    const io = new IntersectionObserver(
      (entries, observer) => {
        const filtered = entries.filter((entry) => entry.isIntersecting);
        filtered.forEach((entry) => {
          observer.unobserve(entry.target);
          renderItems(entry.target);
        });
      },
      // {
      //   rootMargin: '0px 0px ',
      // },
    );
    const mo = new MutationObserver(() => {
      StickerLabel.init(); //Sticker 타이머 온
    });
    const moConfig = {
      childList: true, // 자식노드 추가/제거 감지
    };

    function initObserver() {
      [...document.querySelectorAll('[data-type=io_div]')].forEach((div) => {
        io.observe(div);
        mo.observe(div, moConfig);
      });
    }

    initObserver();
  }, []);

  return Object.keys(priorityList)
    .sort(
      (a, b) =>
        priorityList[a].LIST[0].PRIORITY - priorityList[b].LIST[0].PRIORITY || //PRIORITY 오름차순
        priorityList[b].LIST[0].WID - priorityList[a].LIST[0].WID, //WID 내림차순
    )
    .map((key) => {
      const item = priorityList[key];
      return (
        <div
          className="main-row"
          id={`io_${key.replace('.', '_')}`}
          key={key}
          data-type={'io_div'}
          data-wid_list={item.LIST.map((item) => item.WID).join(',')}
          data-priority={key}
          style={{ height: '200px' }}
        ></div>
      );
    });
};

const WePick = () => {
  if (wepickList?.length === 0) return null;
  if (!wepickItems) return null;

  const priorityList = {};
  wepickList.map((item) => {
    const priority = item.PRIORITY;
    const priorityKey = `${priority}_${item.WID}`;
    if (item.DISP_TYPE === 'S') {
      if (!priorityList[priorityKey]) priorityList[priorityKey] = { LIST: [item] };
      else priorityList[priorityKey].LIST.push(item);
    } else {
      if (!priorityList[priorityKey]) priorityList[priorityKey] = { LIST: [item] };
    }
  });

  if (Object.keys(priorityList).length === 0) return null;

  return <WePickComp priorityList={priorityList} />;
};

export default WePick;
