import { useEffect, useRef } from 'react';
import { CONTENTS_OBJECT } from '@/common/model/constants';

const loadingImg = Public.GetLoadingImage('svg');

const SWIPER_ROOT_ID = 'div_new_mainbanner_slide';

const LIST = PageVariable[CONTENTS_OBJECT.MainBannerList.DATA_NAME] || [];

export const Main = () => {
  const banner_swiper = useRef(null);

  const chk_resize = useRef(null);
  const chk_loading = useRef(null);

  useEffect(() => {
    if (LIST.length === 0) return;

    banner_swiper.current = SlideHelper.CreateSlide(`#${SWIPER_ROOT_ID}`, {
      currentX: 0,
      autoplay: 3000,
      autoplayDisableOnInteraction: false,
      loop: true,
      effect: 'fade',
      slidesPerView: 1,
      slidePerGroup: 1,
      pagination: '.paging',
      paginationType: 'fraction',
      nextButton: '.btn-next',
      prevButton: '.btn-prev',
      onTouchStart: function (slider) {
        this.currentX = slider.touches.currentX;
      },
      onTouchEnd: function (slider, event) {
        if (this.currentX == slider.touches.currentX) {
          if (
            $(event.target).is('.btn-prev') ||
            $(event.target).is('.btn-next') ||
            $(event.target).is('.main-bigbanner__inr') ||
            $(event.target).is('button.more') ||
            $(event.target).is('div.paging.fraction') ||
            $(event.target).is('span.current') ||
            $(event.target).is('span.total')
          ) {
            // 아무것도 하지 않는다.
          } else {
            location.href = $(slider.slides[slider.activeIndex]).children('a').attr('href');
          }
        }

        this.currentX = 0;
      },

      onTransitionEnd: function () {
        const active_li = $(`#${SWIPER_ROOT_ID} ul li.slide.active`);
        const active_li_img = active_li.find('a img');

        // 현재 로딩된 li의 이미지가 로딩이 안된 경우
        if (active_li_img.attr('load') === 'F') {
          DelayImageLoading.GetImageResizeTarget(active_li_img);
        }

        // 현재 로딩된 li의 이전 li 이미지가 로딩이 안된 경우
        if (active_li.prev('li').find('a img').attr('load') === 'F') {
          DelayImageLoading.GetImageResizeTarget(active_li.prev('li').find('a img'));
        }

        // 현재 로딩된 li의 다음 li 이미지가 로딩이 안된 경우
        if (active_li.next('li').find('a img').attr('load') === 'F') {
          DelayImageLoading.GetImageResizeTarget(active_li.next('li').find('a img'));
        }
      },
    });

    function updateSwiper() {
      if (chk_resize.current) {
        clearTimeout(chk_resize.current);
      }

      chk_resize.current = setTimeout(function () {
        banner_swiper.current.update();
      }, 500);
    }

    function autoplayByVisibility() {
      if (!banner_swiper.current) return;
      if (document.visibilityState === 'visible') {
        banner_swiper.current.startAutoplay();
      } else {
        banner_swiper.current.stopAutoplay();
      }
    }

    // 현재 로딩된 li의 이미지가 로딩이 안된 경우
    function checkActiveSlideImg() {
      if (chk_loading.current) return;

      chk_loading.current = setTimeout(() => {
        const active_li_img = $(`#${SWIPER_ROOT_ID} ul li.slide.active a img`);
        if (active_li_img.attr('load') === 'F') {
          DelayImageLoading.GetImageResizeTarget(active_li_img);
        }
        clearTimeout(chk_loading.current);
        chk_loading.current = null;
      }, 200);
    }

    window.addEventListener('resize', updateSwiper);
    document.addEventListener('visibilitychange', autoplayByVisibility);
    window.addEventListener('scroll', checkActiveSlideImg);

    return () => {
      window.removeEventListener('resize', updateSwiper);
      document.removeEventListener('visibilitychange', autoplayByVisibility);
      window.removeEventListener('scroll', checkActiveSlideImg);
    };
  }, []);

  if (LIST.length === 0) {
    return null;
  }

  const rand = Math.floor(Math.random() * 10);

  return (
    <div className="main-bigbanner">
      <div
        className="slide_control"
        id={SWIPER_ROOT_ID}
        data-random_index={rand + 1}
        onMouseEnter={() => {
          if (banner_swiper.current) banner_swiper.current.stopAutoplay();
        }}
        onMouseLeave={() => {
          if (banner_swiper.current) banner_swiper.current.startAutoplay();
        }}
      >
        {LIST.length > 1 ? (
          <div className="main-bigbanner__inr">
            <div className="main-bannerctrl">
              <button className="btn-prev" type="button">
                Previous slide
              </button>
              <button className="btn-next" type="button">
                Next slide
              </button>
            </div>
          </div>
        ) : null}

        <ul className="cont">
          {LIST.map((item, index) => {
            return (
              <li className="slide" key={index} style={{ backgroundColor: item.BACKGROUND_COLOR }}>
                <a href={item.LINK_URL}>
                  <img src={loadingImg} gd_src={item.IMG_URL} width="800" height="450" load="F" alt={item.TITLE} />
                </a>
              </li>
            );
          })}
        </ul>
        <div
          className="main-bigbanner__inr"
          onClick={() => {
            if (LIST.length >= 2) {
              // 화면에 보여질떄 이미지를 로드시켜준다.
              const banner_images = $("div[data-type='div_all_banner_html'] li a").find("img[data-load='N']");
              if ($(banner_images).length > 0) {
                $(banner_images).each(function () {
                  const display_image = $(this).attr('data-src');
                  $(this).attr('src', display_image);
                  $(this).removeAttr('data-src');
                  $(this).attr('data-load', 'Y');
                });
              }

              $("div[data-type='div_all_banner_html']").show();
              $('html').addClass('scroll_fix');
            }
          }}
        >
          {LIST.length > 1 ? (
            <div className="main-bigbanner__paging">
              <div className="paging"></div>
              {LIST.length >= 2 && (
                <button type="button" className="more" data-type="btn_all_view_banner">
                  전체 보기
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
