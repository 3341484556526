import { useEffect } from 'react';
import { CONTENTS_OBJECT } from '@/common/model/constants';

const LIST = PageVariable[CONTENTS_OBJECT.KeyCornerList.DATA_NAME] || [];
const greaterThan11 = LIST.length > 11;

const KeyCornerList = () => {
  useEffect(() => {
    if (!greaterThan11) return;
    SlideHelper.CreateSlide('#div_main_key_corners_swiper', {
      loop: true,
      autoplayDisableOnInteraction: false,
      slidesPerView: 10,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'column',
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      spaceBetween: 40,
      nextButton: '.slide-button__next',
      prevButton: '.slide-button__prev',
    });
  }, []);

  if (LIST.length === 0) {
    return null;
  }

  return (
    <div className="main-key">
      <div className="main-inner">
        <h2 className="main-title">
          {MultiLang.findCommonResourceForceByNation('Main/us/index.aspx', 'MainKeyCornerTitle')}
        </h2>
        <div className={`main-key__corner ${greaterThan11 ? 'mainkey__wrap--slide' : ''}`}>
          {greaterThan11 && (
            <div className="slide-button slide-button--big">
              <button type="button" className="slide-button__prev" id="#mk_prev">
                Prev
              </button>
              <button type="button" className="slide-button__next" id="#mk_next">
                Next
              </button>
            </div>
          )}

          <div className="slide_control" id="div_main_key_corners_swiper">
            <ul className="cont">
              {LIST.map((item, index) => {
                const etcInfoObj = Qoo10FE.Util.Object.jsonParse(item.ETC_INFO);
                const badge = etcInfoObj && etcInfoObj['badge'] ? etcInfoObj['badge'] : '';
                const link_type = etcInfoObj && etcInfoObj['link_type'] ? etcInfoObj['link_type'] : 'M';
                const targetBlankProps = link_type === 'N' ? { target: '_blank' } : null;
                return (
                  <li className="slide" key={index} data-priority={index}>
                    <a href={item.LINK_URL} {...targetBlankProps}>
                      <span className="key-img">
                        <img src={item.IMG_URL} alt="" />
                      </span>
                      <span className="key-txt">{item.TITLE}</span>
                      {badge ? <i className={`badge-${badge}`}>time</i> : null}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyCornerList;
