import { CONTENTS_OBJECT } from '@/common/model/constants';

const LIST = PageVariable[CONTENTS_OBJECT.MainBannerList.DATA_NAME] || [];

export const AllViewer = () => {
  if (LIST.length === 0) {
    return null;
  }

  const closeOnclick = () => {
    $("div[data-type='div_all_banner_html']").hide();
    $('html').removeClass('scroll_fix');
  };

  return (
    <div className="all-promotion" data-type="div_all_banner_html" style={{ display: 'none' }}>
      <div className="all-promotion__content">
        <div className="all-promotion__background" onClick={closeOnclick}></div>
        <ul>
          {LIST.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.LINK_URL}>
                  <img data-src={item.IMG_URL} data-load="N" alt={item.TITLE} />
                </a>
              </li>
            );
          })}
        </ul>
        <button type="button" className="all-promotion__close" onClick={closeOnclick}>
          <span>닫기</span>
        </button>
      </div>
    </div>
  );
};
